<template>
  <v-card>
    <AuctionsPgHeader></AuctionsPgHeader>
    <section class="form-full-width">
      <FormAuctionsPg
        :loading="loading"
        :getAuctionsPg="getAuctionsPg"
        @beforeUpdate="updateAuctionsPg"
      ></FormAuctionsPg>
    </section>
    <section class="form-full-width mb-2">
      <v-row>
        <v-col cols="12">
          <h3>Tipos de Subastas</h3>
        </v-col>
        <v-col v-for="item in auctionsType" :key="item.id" cols="12" md="6" class="mb-4">
          <CardAuctionsType :item="item"></CardAuctionsType>
        </v-col>
      </v-row>
    </section>
  </v-card>
</template>

<script>
import FormAuctionsPg from '@/components/forms/FormAuctionsPg.vue'
import CardAuctionsType from '@/components/cards/CardAuctionsType.vue'
import AuctionsPgHeader from './AuctionsPgHeader.vue'
import useAuctionsPg from '@/composables/useAuctionsPg'

export default {
  components: {
    FormAuctionsPg,
    AuctionsPgHeader,
    CardAuctionsType,
  },
  setup() {
    // Composition API
    const {
      auctionsPg,
      auctionsType,
      loading,
      // methods
      getAuctionsPg,
      updateAuctionsPg,
    } = useAuctionsPg()

    return {
      auctionsPg,
      auctionsType,
      loading,
      // methods
      getAuctionsPg,
      updateAuctionsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
